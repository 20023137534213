import request from '../utils/request'
import config from './config'

/**
 * 积分记录
 */

// 添加积分
export function addVolunteerIntegral(data) {
	return request({
		url: `${config.CIVIL_HOST}/integralRecord/addVolunteerIntegral`,
		method: 'post',
		data,
	})
}

// 下载积分明细模板
export function downloadVolunteerRecordTemplate(data) {
	return request({
		url: `${config.CIVIL_HOST}/integralRecord/downloadVolunteerRecordTemplate`,
		method: 'post',
		data,
		responseType: 'blob',
	})
}

// 兑换积分
export function exchangeVolunteerIntegral(data) {
	return request({
		url: `${config.CIVIL_HOST}/integralRecord/exchangeVolunteerIntegral`,
		method: 'post',
		data,
	})
}
// 导出积分明细
export function exportVolunteerRecordList(data) {
	return request({
		url: `${config.CIVIL_HOST}/integralRecord/exportVolunteerRecordList`,
		method: 'post',
		data,
		responseType: 'blob',
	})
}

// 获取积分明细表
export function getVolunteerIntegralRecordList(data) {
	return request({
		url: `${config.CIVIL_HOST}/integralRecord/getVolunteerIntegralRecordList`,
		method: 'post',
		data,
	})
}
// POST
// /integralRecord/importVolunteerRecordList
// 导入积分明细

// 减少积分
export function reduceVolunteerIntegral(data) {
	return request({
		url: `${config.CIVIL_HOST}/integralRecord/reduceVolunteerIntegral`,
		method: 'post',
		data,
	})
}

// 转让积分
export function transferVolunteerIntegral(data) {
	return request({
		url: `${config.CIVIL_HOST}/integralRecord/transferVolunteerIntegral`,
		method: 'post',
		data,
	})
}

// 获取当前账号下志愿者团队（主账号积分转让）
export function getVolunteerGroupByGovernmentId(params) {
	return request({
		url: `${config.CIVIL_HOST}/integralRecord/getVolunteerGroupByGovernmentId`,
		method: 'get',
		params,
	})
}

// 根据团队获取志愿者列表
export function getVolunteerByGroupId(params) {
	return request({
		url: `${config.CIVIL_HOST}/integralRecord/getVolunteerByGroupId`,
		method: 'get',
		params,
	})
}

