<template>
  <el-dialog title="新增积分" :visible.sync="addIntegralVisible" width="538px" :before-close="handleClose"
    :close-on-click-modal="false">
    <!--表单-->
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px" class="rule-form">
      <el-form-item label="积分体系" prop="integralSystemId">
        <el-select v-model="ruleForm.integralSystemId" placeholder="请选择" class="w230">
          <el-option :label="item.systemName" :value="item.id" v-for="item in integralSystemList" :key="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="新增积分数" prop="integralChange">
        <el-input-number v-model="ruleForm.integralChange" @change="handleChange" :min="1" class="w230">
        </el-input-number>
      </el-form-item>
      <el-form-item label="开始时间" prop="beginTime">
        <el-date-picker
          class="w230"
          v-model="ruleForm.beginTime"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择开始日期时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="结束时间" prop="endTime">
        <el-date-picker
          class="w230"
          v-model="ruleForm.endTime"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择结束日期时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="志愿者服务地点" prop="address">
        <el-input v-model="ruleForm.address" class="w230" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="志愿服务原因" prop="description">
        <el-input type="textarea" v-model="ruleForm.description" placeholder="请输入" class="w230">
        </el-input>
      </el-form-item>
      <el-form-item class="btns">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确认</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
import { getIntegralRecordList } from '@/api/integralSystem'
import { addVolunteerIntegral } from '@/api/integralRecord'
export default {
  name: '',
  components: {},
  props: {
    addIntegralVisible: {
      type: Boolean,
      default: false
    },
    volunteerId: Number
  },
  data() {
    // 1 新增 2 减少 3 转让 4 兑换
    return {
      integralSystemList: [],
      ruleForm: {
        beginTime: '',
        endTime: '',
        description: '',
        idNumber: '',
        integralChange: '',
        integralOperation: 1,
        integralSystemId: '',
        toIdNumber: '',
        toVolunteerId: '',
        volunteerId: this.volunteerId,
        address: ''
      },
      rules: {
        description: [
          { required: true, message: '请输入志愿服务原因', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请输入志愿服务地点', trigger: 'blur' }
        ],
        idNumber: [
          { required: true, message: '请输入身份证号', trigger: 'blur' },
          {
            pattern:
              /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i,
            message: '请输入正确的身份证号',
            trigger: 'blur'
          }
        ],
        integralChange: [
          { required: true, message: '请输入新增积分数', trigger: 'blur' }
        ],
        integralSystemId: [
          { required: true, message: '请选择积分体系', trigger: 'onchange' }
        ],
        beginTime: [
          { required: true, message: '请选择开始日期时间', trigger: 'onchange' }
        ],
        endTime: [
          { required: true, message: '请选择结束日期时间', trigger: 'onchange' }
        ],
        telephone: [
          { required: false, message: '请输入手机号', trigger: 'blur' },
          {
            pattern: /^[1]([3-9])[0-9]{9}$/,
            message: '请正确输入11位手机号码',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {
    this.getIntegralRecordList()
  },
  methods: {
    async getIntegralRecordList() {
      const res = await getIntegralRecordList()
      this.integralSystemList = res.data
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await addVolunteerIntegral(this.ruleForm)
          this.$message.success(res.data)
          this.handleClose()
          this.$emit('updateData')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleChange() {},
    handleClose() {
      // this.$refs['ruleForm'].resetFields()
      this.$emit('update:addIntegralVisible', false)
    }
  }
}
</script>
<style lang="scss" scoped>
.w230 {
  width: 230px;
}
</style>